.nice-select {
  -webkit-tap-highlight-color: transparent !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  border: solid 1px #e8e8e8 !important;
  box-sizing: border-box !important;
  clear: both !important;
  cursor: pointer !important;
  display: block !important;
  float: left !important;
  font-family: inherit !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  height: 42px !important;
  line-height: 40px !important;
  outline: 0 !important;
  padding-left: 18px !important;
  padding-right: 30px !important;
  position: relative !important;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  white-space: nowrap !important;
  width: auto !important;
}
.nice-select:hover {
  border-color: #dbdbdb !important;
}
.nice-select.open,
.nice-select:active,
.nice-select:focus {
  border-color: #999 !important;
}
.nice-select:after {
  border-bottom: 2px solid #999 !important;
  border-right: 2px solid #999 !important;
  content: "" !important;
  display: block !important;
  height: 5px !important;
  margin-top: -4px !important;
  pointer-events: none !important;
  position: absolute !important;
  right: 12px !important;
  top: 50% !important;
  -webkit-transform-origin: 66% 66% !important;
  -ms-transform-origin: 66% 66% !important;
  transform-origin: 66% 66% !important;
  -webkit-transform: rotate(45deg) !important;
  -ms-transform: rotate(45deg) !important;
  transform: rotate(45deg) !important;
  -webkit-transition: all 0.15s ease-in-out !important;
  transition: all 0.15s ease-in-out !important;
  width: 5px;
}
.nice-select.open:after {
  -webkit-transform: rotate(-135deg) !important;
  -ms-transform: rotate(-135deg) !important;
  transform: rotate(-135deg) !important;
}
.nice-select.open .list {
  opacity: 1 !important;
  pointer-events: auto !important;
  -webkit-transform: scale(1) translateY(0) !important;
  -ms-transform: scale(1) translateY(0) !important;
  transform: scale(1) translateY(0) !important;
}
.nice-select.disabled {
  border-color: #ededed !important;
  color: #999 !important;
  pointer-events: none !important;
}
.nice-select.disabled:after {
  border-color: #ccc !important;
}
.nice-select.wide {
  width: 100% !important;
}
.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}
.nice-select.right {
  float: right !important;
}
.nice-select.right .list {
  left: auto !important;
  right: 0 !important;
}
.nice-select.small {
  font-size: 12px !important;
  height: 36px !important;
  line-height: 34px !important;
}
.nice-select.small:after {
  height: 4px !important;
  width: 4px !important;
}
.nice-select.small .option {
  line-height: 34px !important;
  min-height: 34px !important;
}
.nice-select .list {
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11) !important;
  box-sizing: border-box !important;
  margin-top: 4px !important;
  opacity: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  pointer-events: none !important;
  position: absolute !important;
  top: 100% !important;
  left: 0 !important;
  -webkit-transform-origin: 50% 0 !important;
  -ms-transform-origin: 50% 0 !important;
  transform-origin: 50% 0 !important;
  -webkit-transform: scale(0.75) translateY(-21px) !important;
  -ms-transform: scale(0.75) translateY(-21px) !important;
  transform: scale(0.75) translateY(-21px) !important;
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out !important;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out !important;
  z-index: 9 !important;
}
.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}
.nice-select .option {
  cursor: pointer !important;
  font-weight: 400 !important;
  line-height: 40px !important;
  list-style: none !important;
  min-height: 40px !important;
  outline: 0 !important;
  padding-left: 18px !important;
  padding-right: 29px !important;
  text-align: left !important;
  -webkit-transition: all 0.2s !important;
  transition: all 0.2s !important;
}
.nice-select .option.focus,
.nice-select .option.selected.focus,
.nice-select .option:hover {
  background-color: #f6f6f6 !important;
}
.nice-select .option.selected {
  font-weight: 700 !important;
}
.nice-select .option.disabled {
  background-color: transparent !important;
  color: #999 !important;
  cursor: default !important;
}
.no-csspointerevents .nice-select .list {
  display: none !important;
}
.no-csspointerevents .nice-select.open .list {
  display: block !important;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #0055a5;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-select {
  background-color: var(--white);
  border: 2px solid;
  border-color: var(--border-color-9);
  height: 65px;
  -webkit-box-shadow: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-shadow: none;
  padding-left: 20px;
  font-size: 16px;
  color: var(--ltn__paragraph-color);
  width: 100%;
  margin-bottom: 30px;
  border-radius: 0;
  padding-right: 40px;
}

.custom-options {
  color: var(--ltn__paragraph-color);
  background-color: var(--white);
  border: 2px solid;
  border-color: var(--border-color-9);
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 0;
}

.default-option {
  color: var(--ltn__paragraph-color);
  background-color: var(--white);
  border: 2px solid;
  border-color: var(--border-color-9);
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 0;
  font-weight: bold;
}

@media screen and (min-width: 963px) {
  .slide-title {
    font-size: calc(1.5rem + 1.5vw);
    line-height: 55px !important;
    width: calc(100% - 75px);
  }
  .slide-brief {
    width: calc(100% - 50px);
  }
}

#sola-yapis {
  position: absolute;
  top: 90%;
  left: 5%;
}

#saga-yapis {
  position: absolute;
  top: 90%;
  right: 5%;
}

#safkar-bg-100-yil {
  display: block;
  position: absolute;
  background-image: url("/assets/img/safkarBg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 60%;
  height: 100%;
  top: 0%;
  right: 50%;
}

@media screen and (max-width: 992px) {
  #safkar-bg-100-yil {
    display: none;
    position: absolute;
    background-image: url("/assets/img/safkarBg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 60%;
    height: 100%;
    top: 0%;
    right: 50%;
  }
}
